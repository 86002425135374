<template>
  <div class="follow-list">
    <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        class="list"
    >
      <div class="item-list" v-for="item in list" :key="item.id">
        <div class="flex-spa-ver-cen top">
          <div class="flex-ver-cen" @click="imgClick(item.annexUrl)">
            <img :src="item.annexUrl" alt=""/>
            <div class="file-wrap mrg-l-10">
              <div class="file-name">{{ item.annexName }}</div>
              <div class="file-size mrg-t-10">附件大小： {{ item.fileSize }}</div>
            </div>
          </div>
          <van-popover
              v-model="item.showPopover"
              trigger="click"
              placement="left-start"
              :actions="actions"
              @select="onSelect($event, item)"
          >
            <template #reference>
              <div class="time iconfont icon-gengduo"></div>
            </template>
          </van-popover>
        </div>
        <van-divider/>
        <div class="operate flex-spa-ver-cen">
          <div class="name">上传人：{{ item.createBy }}</div>
          <div class="time">{{ item.createTime }}</div>
        </div>
      </div>
    </van-list>
    <van-uploader :after-read="afterRead" class="appendix-btn" v-if="needUpload && isShowAuth">
      <van-button icon="plus" class="upload-btn" type="primary">上传附件</van-button>
    </van-uploader>
  </div>
</template>
<script>
import {deleteAnnex, fetchAnnexList} from '@/api/tool/gen';
import {submiteUpload} from '@/api/system/config';
import {ImagePreview} from "vant";

export default {
  props: {
    businessType: String,
    needUpload: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      finished: true,
      list: [],
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        {text: '下载', className: 'appendix-item-action-name'},
        {text: '删除', className: 'appendix-item-action-name'},
      ],
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const result = await fetchAnnexList({
        businessId: this.$route.query.id,
        businessType: this.businessType,
      });
      result.data.records.forEach((ele) => (ele.showPopover = false));
      this.list = result.data.records || [];
    },

    onLoad() {
    },

    //图片被点击
    imgClick(annexUrl) {
      ImagePreview({images: [annexUrl], closeable: true});
    },

    onSelect(action, row) {
      if (action.text == '下载') {
        this.download(
            'system/file/download',
            {
              annexUrl: row.annexUrl,
            },
            `${row.annexName}`
        );
      } else {
        this.$dialog
            .confirm({
              title: '删除',
              message: '是否确认删除附件信息?',
              className: 'appendix-del-dialog',
              width: '300',
            })
            .then(async () => {
              await deleteAnnex(row.id);
              this.$toast.success('删除成功');
              this.getList();
            })
            .catch(() => {
              // on cancel
            });
      }
    },

    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      const forms = new FormData();
      forms.append('file', file.file);
      forms.append('businessType', this.businessType);
      forms.append('businessId', this.$route.query.id);
      const result = await submiteUpload(forms);
      this.$toast(result.message);
      await this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  box-sizing: border-box;
  background: #fff;
}

.item-list {
  padding: 20px 40px 10px;
  border-top: 20px solid #fafafa;

  .top {
    height: 80px;
    padding-top: 10px;
  }

  img {
    width: 45px;
    height: 56px;
    margin-right: 28px;
  }

  .file-wrap {
    .file-name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .file-size {
      font-size: 22px;
      color: #aaaaaa;
      margin-top: 10px;
    }
  }

  .time {
    font-size: 22px;
    font-weight: 400;
    color: #333;
  }

  .desc {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin: 20px 0;
  }

  .operate {
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    margin: 20px 0;

    .name,
    .time {
      color: #999999;
    }
  }
}

.appendix-btn {
  position: fixed;
  bottom: 0;
  width: 750px;
  height: 111px;
  line-height: 111px;
  background: #fff;
  color: #0091ff;
  font-size: 30px;
  text-align: center;
  margin-top: 111px;
  border: none;
  z-index: 1000;
}

.upload-btn {
  width: 750px;
  text-align: center;
  font-size: 30px;
  background: none;
  border: none;
  color: #0091ff;
}
</style>
